html {
	line-height: 1.5;
}

.ui.labeled.icon.button.right {
	position: relative;
	padding-left: 1.5em !important;
	padding-right: 3.875em !important;
}

.ui.labeled.icon.button.right > .icon {
	left: unset;
	right: 0;
}

.nb-catalog-plugin {
	font-family: 'Roboto', Arial, sans-serif;
	max-width: 1200px;
	margin: auto;
}

.nb-catalog-plugin a {
	text-decoration: none;
}

/* Override default fonts from Semantic */
.ui.header,
.ui.menu,
.ui.dropdown,
.ui.items > .item > .content > .header,
.ui.card > .content > .header,
.ui.button,
.ui.input > input,
.ui.form input[type="text"],
.ui.form input[type="date"]
{
	font-family: inherit;
}

.ui.menu .item,
.ui.card > .content > .header:not(.ui),
.ui.items > .item > .content > .header:not(.ui) {
	color: #1a1a1a;
}

.ui.menu,
.ui.compact.menu:not(.secondary) .item:last-child,
.ui[class*="top attached"].menu {
	border-radius: unset;
}

.borderless {
	border: unset !important;
	box-shadow: unset !important;
}

.unpadded {
	padding: 0 !important;
}

@media (max-width: 1264px) {
	.nb-catalog-plugin {
		width: 100%;
		margin: auto;
	}
	.nb-generator {
		flex-direction: column;
	}
	.tabs {
		margin-top: 48px;
	}
	.ui.basic.inverted.segment.unpadded.tab-container {
		width: 100% !important;
	}
	.ui.segment.dimmable.options-container {
		max-width: 90% !important;
		margin: auto !important;
	}
}
